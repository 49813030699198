import Chart from 'chart.js/auto';

export default class RsChart {
  constructor(url, title, canvas_id, progress_id, doneCallback) {
    this.$canvas = document.getElementById(canvas_id)
    this.$progress = document.getElementById(progress_id)
    this.doneCallback = doneCallback
    this.title = title
    this.url = url
    this.config = {}
    this.data = {}
    this.color = new ChartColor()
  }

  setup() {
  }

  fetch(selectedValues) {
    this.data = {};
    var data = this.data

    var $progress = this.$progress;
    var meter = 0.0
    var config = {
      type:  'line',
      data: data,
      options: {
        title: {
          display: true,
          text: this.title
        },
        scales: {
          yAxes: [
            {
              display: true,
              ticks: {
                min: 0
              }
            }
          ],
        },
        animation: {
          duration: 1000,
          onProgress: function(animation) {
            if(meter > $progress.max) {
              return
            }

            meter += ($progress.max - meter) / 100
            $progress.value = meter
          }
        }
      }
    };

    var ctx = this.$canvas.getContext('2d');
    var chart = new Chart(ctx, config);
    var color = this.color

    $progress.classList.remove('u-hidden')
    var that = this

    $.ajax(this.url,
      {
        'dataType': 'json',
        'data': {'project_ids': selectedValues}
      })
    .done(function  (resp) {
      var datasets = []
      resp['project_ids'].forEach(function (project_id, index) {
        datasets.push({
          label: resp['projects'][project_id]['title'],
          backgroundColor: color.withIndex(index),
          borderColor: 'rgb(0,0,0)',
          borderWidth: 1,
          data: resp['data'][project_id]
        })
      });

      config['data']['labels'] = resp['dates'];
      config['data']['datasets'] = datasets;
      config['options']['scales'] = {y: {beginAtZero: true}};
      chart.update();
      meter = 100
      $progress.classList.add('u-hidden')

      if(that.doneCallback) {
        that.doneCallback(resp)
      }
    })
  }
}
