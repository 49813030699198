export default class ChartColor {
  constructor() {
    this.chartColors = new Map()
    this.chartColors.set('red', 'rgb(255, 99, 132)');
    this.chartColors.set('orange', 'rgb(255, 159, 64)');
    this.chartColors.set('yellow', 'rgb(255, 205, 86)');
    this.chartColors.set('green', 'rgb(75, 192, 192)');
    this.chartColors.set('blue', 'rgb(54, 162, 235)');
    this.chartColors.set('purple', 'rgb(153, 102, 255)');
    this.chartColors.set('grey', 'rgb(201, 203, 207)');
  }

  withIndex(index) {
    var colors = this.chartColors
    if(colors.size == 0) {
      return null;
    }
    return Array.from(colors)[index % colors.size][1];
  }
}

